import React from 'react';
import {Button, InfoMedia, SecSubTitle, SecTitle} from '../../components';
import {Link} from "react-router-dom";

const AboutSevenDownload = ({data})=> (
  <div className="background-image" style={{backgroundImage: 'url(images/bg/about-bg-5-1.jpg)'}}>
    <div className="container container-style1">
      <div className="row flex-row-reverse align-items-center gx-70">
        <div className="col-lg-6 col-xl">
          <img src={data?.img} alt="aboutthumb"/>
        </div>
        <div className="col-lg-6 col-xl-auto">
          <div className="about-box2">
            <SecSubTitle><i className="fas fa-bring-forward"/>{data?.title}</SecSubTitle>
            <SecTitle className="text-capitalize h1">{data?.subtitle}</SecTitle>
            <p>{data?.desc}</p>
            <div className="row gx-0 align-items-center flex-row-reverse justify-content-end mt-sm-4 pt-sm-4 mb-30 pb-10">
              <div className="col-sm-auto">
                <Link target="_blank" to="https://play.google.com/store/apps/details?id=com.esyncross.b1solidScan&pcampaignid=web_share">
                  <Button>Google Play<i className="fab fa-google-play "/></Button>
                </Link>
              </div>
              <div className="col-sm-auto about-call">
                <Link target="_blank" to="https://apps.apple.com/us/app/solidscan/id6738659424">
                  <Button>App Store<i className="fab fa-app-store"></i></Button>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutSevenDownload;