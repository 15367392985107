import React, {useState} from 'react';
import Slider from "react-slick";
import {IconButton, InfoMedia, SecSubTitle, SecTitle} from '../../components';


// Testimonial Data


const TestimonialTwo = ({className,data,title,subtitle,bigImage=false,lessSlides=false}) => {

    // Select All Slider
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [slider3, setSlider3] = useState(null);

    // Next Slide Handler
    const nextSlideHandler = () => {
        slider1.slickNext();
    }

    // Prev Slide Handler
    const prevSlideHandler = () => {
        slider1.slickPrev();
    }

    // Image & Text Slider Settings
    const settingOne = {
        autoplay: true,
        autoplaySpeed: 12000,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    };


    // Author Box Slider Settings
    const settingsTwo = {
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 12000,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    return (
        <div className={`background-image ${className || ''}`}
             style={{backgroundImage: 'url(images/bg/testi-bg-2-1.jpg)'}}>
            <div className="container position-relative">
                {!lessSlides && <div className="testimonial-arrow d-none d-xxl-block">
                    <IconButton className="style5" onclick={prevSlideHandler}><i
                        className="far fa-arrow-left"/></IconButton>
                    <IconButton className="style5" onclick={nextSlideHandler}><i
                        className="far fa-arrow-right"/></IconButton>
                </div>}
                <div className="row gx-60">
                    <SecTitle className="text-capitalize h1">{title || "WHAT WE HAVE INTEGRATED SO FAR"}</SecTitle>
                    <div className={bigImage ? "col-md-12 col-lg-8":"col-lg-5 col-xl-6"}>
                        <Slider className="img-box6" {...settingOne} asNavFor={slider2 && slider3}
                                ref={(slider) => setSlider1(slider)}>
                            {data.map(item => (
                                <div key={item.id}>
                                    <img className={bigImage ? "imgTestimonialBig" : "imgTestimonialNormal"} src={item.image} alt="testmonial"/>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className={bigImage ? "col-md-12 col-lg-4":"col-lg-7 col-xl-6 "}>
                        <SecSubTitle><i className="fas fa-bring-forward"/>{subtitle || "PROJECT ALONG OUR JOURNEY"}</SecSubTitle>
                        <Slider className="testi-text-slide gx-0" {...settingOne} asNavFor={slider3 && slider1}
                                ref={(slider) => setSlider2(slider)}>
                            {data.map(item => (
                                <div key={item.id}>
                                    <SecTitle className="text-capitalize h1">{item.name}</SecTitle>
                                    <p className="testi-text">{`“${item.text}”`}</p>
                                </div>
                            ))}
                        </Slider>

                    </div>
                </div>
                <Slider className="testi-author-slide row" {...settingsTwo} asNavFor={slider1 && slider2} ref={(slider) => setSlider3(slider)}>
                    {data.map(item => (
                        <div key={item.id}>
                            <InfoMedia
                                styleFont={{fontSize:item.name?.length < 25 ? null : 16}}
                                className="p-3 author-style1 align-items-center"
                                       title={item.name}
                                       icon={item.icon}
                                       styleIcon={{
                                           backgroundColor:'#1B3C69',height:60,
                                           color:'white',fontSize:22,
                                           display:'flex',justifyContent:'center',alignItems:'center'
                                       }}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default TestimonialTwo;