import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, SecSubTitle, SecTitle, Seo, TitleWrap} from '../components';
import {
    AboutFour,
    AboutSeven,
    AboutThree,
    ContactTwo,
    FeatureTwo,
    FooterOne,
    HeaderOneCopy, TeamThree,
    TestimonialTwo
} from '../containers';
import AboutSevenImages from "../containers/Abouts/AboutSevenImages";
import AboutFourTimed from "../containers/Abouts/AboutFourTimed";
import SolidScanWeb from "../data/SolidScanWeb.json";
import AboutSevenDownload from "../containers/Abouts/AboutSevenDownload";
import CtaOne from "../containers/Ctas/CtaOne";


const aboutSevenData = [
    {
        flip: false,
        title: 'Scan and Digitize Documents',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Capture high-quality digital copies of your documents using advanced scanning tools, ensuring clarity and accuracy for seamless management.'
    },
    {
        flip: true,
        title: 'Edit, Crop, and Clean Documents Captured Via Camera',
        imgPath: 'images/SolidScan/Edit.png',
        description: 'Easily refine scanned documents with powerful editing tools, including cropping and cleaning, to prepare files for professional use.'
    },
    {
        flip: false,
        title: 'PDF Document Generation',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Convert scanned and edited documents into PDF files with a single tap, creating standardized formats suitable for e-filing.'
    },
    {
        flip: true,
        title: 'Seamlessly Upload Documents to ERP Systems',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Effortlessly connect with ERP systems to attach files directly to transactions, such as invoices or purchase orders, simplifying workflows.'
    },
    {
        flip: false,
        title: ' Backend Portal Handles Browsing, Viewing And Exporting Attachments',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Manage your document library with ease using the backend portal, designed for efficient browsing, viewing, and exporting of attachments, streamlining e-filing processes.'
    }
];

const aboutSolidScan = {
    title: "SolidScan – Smart Document Management",
    subtitle: "Scan, Edit, Convert & Upload Seamlessly",
    desc: "Download SolidScan today to transform your document management experience. Capture, edit, and convert documents into PDFs with ease, then upload them directly to ERP systems. With high-quality scanning and smart editing tools, SolidScan ensures efficiency and accuracy in document handling.",
    about: "Designed for professionals and businesses, SolidScan streamlines document digitization with high-quality scanning, smart editing tools, and secure PDF generation. The backend portal enables effortless browsing, viewing, and exporting of attachments, making document management more efficient.",
    downloadLinks: {
        appStore: "https://apps.apple.com/app/idXXXXXXX", // Replace with actual link
        googlePlay: "https://play.google.com/store/apps/details?id=XXXXXXXX" // Replace with actual link
    },
    img:'images/about/ab-2-1.jpg'
};

const aboutFourData = {
    title: 'DIGITALIZE FILING PROCESS',
    subtitle: 'Ensuring Ease & save efforts',
    points: [
        {
            title: "Manage Attachments",
            text: "Browsing, view and download attachments, switch between your companies.",
            img: 'images/SolidScan/Mobile/Home.png',
        },
        {
            title: "Browse Transactions & Select Document",
            text: "Easily navigate records and select documents by number, partner, reference, or date.",
            img: 'images/SolidScan/Mobile/Types.png',
        },
        {
            title: "Create New Document",
            text: "Give the document a name to keep it organized and ready for scanning.",
            img: 'images/SolidScan/Mobile/add.png',
        },
        {
            title: "Add New Page",
            text: "Choose how to add a new page: scan with the camera or select from the gallery.",
            img: "images/SolidScan/Mobile/method.png"
        },
        {
            title: "Scan Files",
            text: "Capture documents automatically or manually, with options to crop, edit, and save.",
            img: 'images/SolidScan/Mobile/Scaning.png',
        },
        {
            title: "Generate and Upload",
            text: "Convert documents to PDF and upload them directly to your ERP system.",
            img: 'images/SolidScan/Mobile/upload.png',
        },

    ]
}


const SolidScan = () => (
    <Fragment>
        <Seo title="SolidScan"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="SolidScan"
                    pageSubTitle={"E-Filing solution"}
                    pageDesc={"Seamless scanning, editing, and uploading of documents to SAP Business One"}
                    bgImage="images/bg/bg-SolidScan.png"/>


        <div className="container space-extra-bottom">
            <TitleWrap className="text-center space-top ">
                <SecTitle className="text-capitalize h1">SolidScan – Document Scanner<br/>
                    Easiest way to Scan Documents</SecTitle>
                <SecSubTitle> NAVIGATE EFFORTLESSLY THROUGH SOLIDSCAN'S INTUITIVE INTERFACE With Our Friendly User
                    Interface </SecSubTitle>
            </TitleWrap>


            <div className={'w-100p d-flex align-items-center justify-content-center'}>
                <div className={'images-ss-row'}>
                    <img src={'images/SolidScan/Mobile/Scaning.png'} alt="tabthumb" style={{
                        objectFit: 'cover',
                        width: '100%',
                        transform: "scale(0.8)",
                        position: 'absolute',
                        marginRight: '115%',
                        marginTop: '15%'
                    }}/>
                    <img src={'images/SolidScan/Mobile/Home.png'} alt="tabthumb"
                         style={{objectFit: 'cover', width: '100%', zIndex: 1}}/>
                    <img src={'images/SolidScan/Mobile/Setting.png'} alt="tabthumb" style={{
                        objectFit: 'cover',
                        width: '100%',
                        transform: "scale(0.8)",
                        position: 'absolute',
                        marginLeft: '115%',
                        marginTop: '15%'
                    }}/>

                </div>
            </div>

            <p className="author-degi space-top text-center"> SolidScan is an E-Filing solution, enables seamless
                scanning, editing, and uploading of documents to
                ERP systems. Streamline e-filing and digitize your
                document management by attaching files to
                transactions like invoices and purchase orders, while
                effortlessly managing and sharing them through a
                powerful backend portal</p>


        </div>



       {/* {aboutSevenData?.map((item, index) => <AboutSevenImages
            index={index}
            flip={item.flip}
            title={item.title}
            imgPath={item.imgPath}
            description={item.description}
        />)}*/}

        <AboutFourTimed data={aboutFourData} className="space space-extra-bottom"/>

        <AboutSevenDownload data={aboutSolidScan} />
        <TestimonialTwo
            bigImage={true} lessSlides={true}
            title={"Complete Control Center"}
            subtitle={"Manage, view, and configure with ease."}
            data={SolidScanWeb} className="space-top space-extra-bottom"

        />

        <CtaOne className="space"/>

        <div className={'row align-items-center justify-content-center'}>
            <div className={'col-md-7'}>
                <TitleWrap className="text-center space-top ">
                    <SecTitle className="text-capitalize h1"> Why Choose SolidScan?
                    </SecTitle>
                    <p className="author-style1 text-center">
                        We stand out by offering a solution that is easy to use, ensures data security, and leverages
                        automation to streamline processes, all while being cost-effective. Our platform seamlessly
                        integrates with existing systems, promotes sustainability by reducing waste, enables quick
                        search and retrieval of documents, and is backed by exceptional customer support for a complete,
                        reliable experience.
                    </p>
                </TitleWrap>
            </div>
            <div className={'col-md-5 row align-items-center justify-content-center'}>
                <img src={'images/SolidScan/Mobile/Home.png'} alt="tabthumb" style={{
                    objectFit: 'cover',
                    width: '60%',
                    marginTop:-50,zIndex:25
                }}/>

            </div>
        </div>



        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SolidScan;