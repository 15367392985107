import React from 'react';
import {Button, SecSubTitle, SecTitle} from '../../components';
import {Link} from "react-router-dom";


const CtaOne = ({className}) => {

    const handleDownload = () => {
        const fileUrl = "Files/B1SolidScan.pdf"; // Update with the actual file path
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "file.pdf"); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={`z-index-common background-image ${className || ''}`}
          style={{backgroundImage: 'url(images/bg/bg-read.png'}}>
        <div className="container">
            <div className="row text-center text-lg-start align-items-center justify-content-between">
                <div className="col-lg-auto">
                    <SecSubTitle className="">Unlock the Power of Digital Efficiency!</SecSubTitle>
                    <SecTitle className="cta-title1 h1">Get the Full Overview</SecTitle>
                </div>
                <div className="col-lg-auto">
                    <Button onclick={handleDownload}>Get The Brochure<i
                        className="far fa-arrow-right fa-rotate-90"/></Button>
                </div>
            </div>
        </div>
    </div>)
};

export default CtaOne;